@import '~antd/dist/antd.less';
@import (optional) '~@digatex/digatex-ui-lib/dist/@{theme}.less';
@import '~file-icon-vectors/dist/file-icon-vectors.min.css';
@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css';

.jsoneditor-poweredBy {display: none}

body {
  margin: 0;
  font-family: Calibri, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header.ant-layout-header {
  background: @header-background;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.site-layout-background {
  background: #fff;
}

.ant-upload.ant-upload-drag {
  height: unset;
}

.react-checkbox-tree {
  display: inline-block;
  float: left;
  white-space: nowrap;
}

/*.react-checkbox-tree span.rct-bare-label {*/
/*  flex-grow: 1;*/
/*  align-content: flex-start;*/
/*}*/


.react-checkbox-tree .rct-node-leaf span:not(.rct-collapse) {
  flex-grow: 1;
  display: flex;
}

#logo {
  height: 48px;
}

/*canvas {*/
/*  outline: none;*/
/*  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); !* mobile webkit *!*/
/*}*/
/*.ant-tree-list-scrollbar {*/
/*  display: block !important;*/
/*}*/

/** {*/
/*  animation-duration: 0s !important;*/
/*}*/


/*.react-checkbox-tree .rct-collapse {*/
/*  flex-grow: 0;*/
/*}*/



/*.react-checkbox-tree span.rct-bare-label:hover {*/
/*  background: #999;*/
/*  cursor: pointer;*/
/*}*/

/*#tags-tree-select .leaf input {*/
/*  display: none;*/
/*}*/


/*#tags-tree-select .leaf .node.disabled {*/
/*  color: 'red'*/
/*}*/

/*#tags-tree-select .dropdown a {*/
/*  display: none;*/
/*}*/


.editable-field > input:focus {
  margin:-8px;
  padding-left: 4px;
  border-radius: 0;
  border:6px solid @color-turquoise !important;
  transition: none !important;
}


.editable-select-field-cell {
  border-radius: 0;
  border: 6px solid @color-turquoise !important;
  position: relative;
  z-index: 1500;
}

.editable-select-field {
  width: 100%;
  border-radius: 0;
  transition: none !important;
}

td { white-space: pre-wrap; }

#minimap-slider {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% { opacity: 0; }
}

#home-button > svg {
  position: relative;
  top: calc(50% - 16px);
}


.drag-sorting-table tr.drop-over-downward td {
  border-bottom: 2px dashed @primary-color;
}

.drag-sorting-table tr.drop-over-upward td {
  border-top: 2px dashed @primary-color;
}

.medium-circle {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin-left: 0;
  margin-right: 2px;
  width: 8px;
}


.image-viewer-drag {
  user-select: none;
  width: 100%;
  cursor: ns-resize;
  background: #f0f2f5;
  height: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: background 0.1s ease-out;
}

.image-viewer-drag:hover,
.image-viewer-drag:active {
  background: #377dce;
}

@theme: digatex;